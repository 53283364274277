
// @ts-nocheck
import locale__app_apps_nuxt_lang_en_js from "../lang/en.js";
import locale__app_apps_nuxt_lang_de_js from "../lang/de.js";
import locale__app_apps_nuxt_lang_it_js from "../lang/it.js";
import locale__app_apps_nuxt_lang_es_js from "../lang/es.js";
import locale__app_apps_nuxt_lang_fr_js from "../lang/fr.js";


export const localeCodes =  [
  "en",
  "de",
  "it",
  "es",
  "fr"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.js", load: () => Promise.resolve(locale__app_apps_nuxt_lang_en_js), cache: true }],
  "de": [{ key: "../lang/de.js", load: () => Promise.resolve(locale__app_apps_nuxt_lang_de_js), cache: true }],
  "it": [{ key: "../lang/it.js", load: () => Promise.resolve(locale__app_apps_nuxt_lang_it_js), cache: true }],
  "es": [{ key: "../lang/es.js", load: () => Promise.resolve(locale__app_apps_nuxt_lang_es_js), cache: true }],
  "fr": [{ key: "../lang/fr.js", load: () => Promise.resolve(locale__app_apps_nuxt_lang_fr_js), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "label": "English",
      "files": [
        "/app/apps/nuxt/lang/en.js"
      ]
    },
    {
      "code": "de",
      "label": "German",
      "files": [
        "/app/apps/nuxt/lang/de.js"
      ]
    },
    {
      "code": "it",
      "label": "Italian",
      "files": [
        "/app/apps/nuxt/lang/it.js"
      ]
    },
    {
      "code": "es",
      "label": "Spanish",
      "files": [
        "/app/apps/nuxt/lang/es.js"
      ]
    },
    {
      "code": "fr",
      "label": "French",
      "files": [
        "/app/apps/nuxt/lang/fr.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false,
    "redirectOnRoot": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "useCookie": true,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "label": "English",
    "files": [
      {
        "path": "/app/apps/nuxt/lang/en.js"
      }
    ]
  },
  {
    "code": "de",
    "label": "German",
    "files": [
      {
        "path": "/app/apps/nuxt/lang/de.js"
      }
    ]
  },
  {
    "code": "it",
    "label": "Italian",
    "files": [
      {
        "path": "/app/apps/nuxt/lang/it.js"
      }
    ]
  },
  {
    "code": "es",
    "label": "Spanish",
    "files": [
      {
        "path": "/app/apps/nuxt/lang/es.js"
      }
    ]
  },
  {
    "code": "fr",
    "label": "French",
    "files": [
      {
        "path": "/app/apps/nuxt/lang/fr.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
