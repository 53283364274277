import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/pages/runtime/validate.js";
import geolocation_45global from "/app/apps/nuxt/middleware/geolocation.global.js";
import google_45global from "/app/apps/nuxt/middleware/google.global.js";
import maintenance_45global from "/app/apps/nuxt/middleware/maintenance.global.js";
import trailing_45slash_45global from "/app/apps/nuxt/middleware/trailing-slash.global.js";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  geolocation_45global,
  google_45global,
  maintenance_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/apps/nuxt/middleware/auth.js")
}