import revive_payload_client_a9tlq4HuvX from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fS5huNg0LT from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2ycfR3ULsN from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_cnDQyYThQp from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@types+node@20.16.10_terser@5_l4nuwzg6rlbtc3djs5ad3vj72u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import debug_wAtKrs8mwx from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_p2LrRtJJeX from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sHcvF2BSZB from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BzBGWEIFjZ from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_awdOnGcoZY from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M6CG7kSrTJ from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.5_typescript@5.6.2_vue@3.5.10_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_MTwUvtwUSm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_B5rZAszqhZ from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_qnztmh2ff2ce3v34uzx7cqh5yu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/apps/nuxt/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_VUmHOoLgtn from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_F9Lt45jj0J from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.5_vue@3.5.10_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_a9tlq4HuvX,
  unhead_fS5huNg0LT,
  router_2ycfR3ULsN,
  _0_siteConfig_cnDQyYThQp,
  debug_wAtKrs8mwx,
  payload_client_p2LrRtJJeX,
  navigation_repaint_client_sHcvF2BSZB,
  check_outdated_build_client_BzBGWEIFjZ,
  chunk_reload_client_awdOnGcoZY,
  plugin_vue3_M6CG7kSrTJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MTwUvtwUSm,
  plugin_B5rZAszqhZ,
  formkitPlugin_pZqjah0RUG,
  switch_locale_path_ssr_VUmHOoLgtn,
  i18n_F9Lt45jj0J
]