import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@8.57.1_ioredis@5.4.1_magicast@0_ms5rzced5msldq27shvibq6ahy/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/apps/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}